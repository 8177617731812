.Navigation {
    font-family: Oswald, sans-serif;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Nav-Row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0px 10px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .Nav-Item {
    align-items: center;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 60px;
    text-decoration: none;
  }
  
  .Nav-Item.selected {
    align-items: center;
    color: #5a189a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 60px;
  }
  
  .Nav-Text {
    font-size: 12px;
    font-weight: 300;
    margin-top: 4px;
  }