.Home {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    gap: 20px;
}

.header {
    width: 100%;
}

.header h1 {
    color: #333;
    font-size: 1.2em;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.content img {
    width: 100px;
    height: auto;
    border-radius: 10px;
}

.content form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.login input {
    padding: 12px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
}