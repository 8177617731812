.ChipScanner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    background-color: aquamarine;
}

.alert {
    box-sizing: border-box;
    font-size: 12px;
    padding: 10px;
    width: 100%;
}

.alert.error {
    background-color: #dc3545;
    color: #fff;
}

.alert.success {
    background-color: #28a745;
    color: #fff;
}

.select-container {
    padding: 0 20px;
}

.table-title.chip,
.table-value.chip {
    max-width: 25%;
}

.footer {
    position: fixed;
    bottom: 60px;
    width: 100%;
    background-color: white;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.btn-container {
    padding: 10px;
    text-align: center;
}

.btn.btn-primary,
.btn.btn-secondary {
    margin: 0 auto;
    width: 100%;
    font-weight: 600;
    padding: 16px 0;
}

.btn.btn-primary {
    background-color: #8035c9;
}

.btn.btn-primary:active {
    background-color: #8035c9;
}

.btn.btn-secondary {
    background-color: darkgray;
}

.btn.btn-secondary:active {
    background-color: gray;
}