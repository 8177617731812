.Inventory-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.Inventory-Header {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.table-header-title.action {
    flex-basis: 10%;
}

.status-text {
    display: block;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    word-wrap: break-word;
}