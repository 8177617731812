.App {
  height: 100vh;
}

.btn {
  background-color: #5A189A;
  border: none; /* Remove borders */
  border-radius: 100px;
  color: white; /* White text */
  padding: 8px 16px;
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  text-transform: uppercase;
  font-family: Inter, sans-serif;
}

h1 {
  font-weight: 500;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  color: #212738;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  color: #212738;
}

.header {
  padding: 0px 20px;
  display: flex;
  box-sizing: border-box;
}

.alert-text {
  background-color: #FF383810;
  font-size: 12px;
  font-weight: 300;
  color: #FF3838;
  padding: 8px;
  border-radius: 10px;
}

.status_text {
  margin: 0 auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: Inter, sans-serif;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 20px 5px 20px;
}

.response {
  background-color: #00c851;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  max-width: 100%;
  margin: auto;
}

table {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DEE6EB;
  border-radius: 10px;
  overflow: auto;
  max-height: calc(100vh - 200px);
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  background-color: #EFF4F7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
  font-size: 12px;
  padding: 15px 20px;
  gap: 10px;
}

.table-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  font-size: 12px;
  border-bottom: 1px solid #eee;
  gap: 10px;
}

.table-title, .table-value {
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-button {
  flex-basis: 10%;
  margin-left: auto;
  margin-right: 0;
}

tfoot {
  text-align: center;
  padding: 10px 20px;
  font-size: 12px;
}